.menu-hero {
  margin-top: 10%;
}

.game-container {
  margin-top: 3%;
}

.is-game-title {

  background-color: $game-dark;
  color: $white;
}

.is-game-tile {
  border-radius: 4px;
  padding: 1.25rem 1.25rem 1.25rem 1.25rem;
  position: relative;
  background-color: $game-dark;
  color: $white;
}

.is-info-bar {
  border-radius: 4px;
  padding: 1.25rem 1.25rem 1.25rem 1.25rem;
  position: relative;
  background-color: $info;
  color: $white;
  align-content: center;
  line-height: 1;
}

.info-background {
  background-color: $info;
}

.danger-background {
  background-color: $danger;
}

.info-line {
  padding: 0.25rem 1.25rem 1.25rem 1.25rem;
  font-size: 150%;
}

.team-name {
  border-radius: 3px;
  padding: 0 0.25rem 0 0.25rem;
  position: relative;
  background-color: $game-white;
  align-content: center;
  border-style: solid;
  border-width: 2px;
}

.blue-name {
  color: $game-blue;
  border-color: darken($game-blue, 20%);

}

.red-name {
  color: $game-red;
  border-color: $game-red;
  //border-color: darken($game-red, 20%);
}

.rules-modal {
  position: fixed;
  top: 30px;
  bottom: 30px;
  right: 30px;
  left: 30px;
  border: solid 6px $game-dark;
  background-color: $game-white;
  color: $black;
  line-height: 1;
  border-radius: 5px;

}

@media screen and (max-width: 480px) {
  .rules-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    background-color: $game-white;
    color: $black;
    line-height: 1;
    border: solid 3px $game-dark;
    border-radius: 5px;

  }
}

.overlay {
  background-color: rgba($game-dark, .7);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.move-dot {
  border-radius: 20px;
  margin-left: 5px;
  margin-right: 5px;
  width: 40px;
  padding: 0;
  position: relative;
  background-color: $game-white;
  align-content: center;
  border-style: solid;
  border-width: 2px;
}


.menu-button {
  display: block;
  text-align: left;
  margin-top: 20px;
  font-weight: 500;
  font-size: 0.7em;
  background-color: $white;
  border-width: 2px !important;
  border-style: solid !important;

  text-decoration: none !important;
  border-radius: 5px;
}

.sidebar-button {
  display: block;
  text-align: center;
  font-weight: 500;
  font-size: 1.5em;
  margin-top: 20px;
  background-color: $white;
  border-width: 2px !important;
  border-style: solid !important;
  text-decoration: none !important;
  border-radius: 5px;
}

.rules-button {
  display: block;
  position: absolute;
  text-align: center;
  font-weight: 500;
  font-size: 1.3em;
  background-color: $white;
  border-width: 1.5px !important;
  border-style: solid !important;
  text-decoration: none !important;
  border-radius: 5px;
}

.rules-back-button {
  bottom: 20px;
  right: 20px;
  left: 20px;
}

.rules-next-button {
  bottom: 80px;
  right: 20px;
  left: 53%;
}

.rules-prev-button {
  bottom: 80px;
  right: 53%;
  left: 20px;
}

.rules-title {
  text-align: center;
  font-weight: 500;
  font-size: 1.3em;
  padding-top: 5px;
}



.rules-text {
  ul {
    list-style-type: square;
    li {
      padding: 5px;

    }
  }
}


.rules-image {
  margin: 5px;
  margin-left: 15%;

  max-width: 70%;
}

@media screen and (min-width: 768px) {
  .rules-image {
    margin: 5px;
    margin-left: 20px;
    max-width: 50%;
  }
}

.button-on-danger {
  $hov-col: darken($danger, 20%);

  color: $hov-col;

  border-color: $hov-col;

  &:visited {
    color: $hov-col;
    border-color: $white;
  }

  &:hover {
    background-color: $hov-col;
    color: $white;
    border-color: $white;
  }
}

.button-on-info {
  $hov-col: darken($info, 20%);

  color: $hov-col;

  border-color: $hov-col;

  &:visited {
    color: $hov-col;
    border-color: $white;
  }

  &:hover {
    background-color: $hov-col;
    color: $white;
    border-color: $white;
  }
}

.button-on-warning {
  $hov-col: darken($warning, 20%);

  color: $hov-col;

  border-color: $hov-col;

  &:visited {
    color: $hov-col;
    border-color: $white;
  }

  &:hover {
    background-color: $hov-col;
    color: $white;
    border-color: $white;
  }

  &:disabled{
    background-color: darken($game-dark, 20%);
    color: $white;
    border-color: $white;
  }
}

.connection-status{
  margin-bottom: 15px;

}

.dot{
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
}

.red{
  background-color: $game-red;
}


.green{
  background-color: $game-green;
}

.my-footer{
  background-color: $game-white;
  color: darken($game-yellow,  20%);
  font-weight: 600;
  font-size: 0.8rem;
  padding: 6rem 1.5rem 5rem;
  line-height: 1rem;

}