$background-color: #f5f5f5;


$game-dark: #7b8d8e;

//$game-dark: #63676d;
$game-red: #e45655;
$game-yellow: #f1a94e;
$game-blue: #44b3c3;
$game-white: #f2edd8;
$game-brown: #5d4c46;
$game-green: #55e45a;

$white:            #fff;
$primary:          #42b983;
$secondary:        #8d7edc;
$success:          #65e892;
$danger:           $game-red;
$warning:          #F5A623;
$info:             $game-blue;