.game-field{
  max-width: 82vh;


}

.field-row{
  width: 100%;

  display:inline-block;
  margin: 0;

}
.cell{
  padding: 2px;

  display:inline-block;
  width: 12.5%; // think about setting this from js, since it is 100/8
  background: $game-dark;

}